
<template>
  <div class="MobileCheckin">
    <div class="imgBox">
      <img src="../../assets/mobile/product/checkin.png" class="img" />
    </div>
    <div class="param">
      <div class="title">自助入住机</div>
      <div class="tit">相关参数</div>
      <div class="text">
        <div class="name">机柜</div>
        <div class="txt">高：1850</div>
        <div class="txt">宽：560</div>
        <div class="txt">厚：380</div>
        <div class="txt">材质：全钢</div>
      </div>
      <div class="text">
        <div class="name">屏幕</div>
        <div class="txt">屏幕：19 吋电容触摸显示器</div>
        <div class="txt">分辨率：1366×768</div>
        <div class="txt">广告屏幕：21.5 吋彩色液晶显示器</div>
        <div class="txt">分辨率：1920×1080</div>
      </div>
      <div class="text">
        <div class="name">内置主机</div>
        <div class="txt">芯片组：H61</div>
        <div class="txt">CPU：双核2.7GHz</div>
        <div class="txt">内存：DDR3 2GB</div>
        <div class="txt">储存：1TB硬盘</div>
        <div class="txt">视频输出：HDMI+VGA</div>
      </div>
      <div class="text">
        <div class="name">发卡器</div>
        <div class="txt">房卡类型：RFID</div>
        <div class="txt">发卡箱容量：120张</div>
        <div class="txt">回收卡箱容量：80张</div>
      </div>
      <div class="text">
        <div class="name">人脸摄像头</div>
        <div class="txt">分辨率：1280×1080</div>
        <div class="txt">像素：1500万</div>
      </div>
      <div class="text">
        <div class="name">二维码扫描仪（选配）</div>
        <div class="txt">读取旋转角度：360度</div>
        <div class="txt">解码时间：小于2秒</div>
      </div>
      <div class="text">
        <div class="name">凭条打印机</div>
        <div class="txt">打印速度：200mm/秒</div>
        <div class="txt">打印纸宽：58mm</div>
        <div class="txt">其他功能：缺纸报警、纸将尽报警、自动切纸</div>
      </div>
      <div class="text">
        <div class="name">银联卡支付（选配）</div>
        <div class="txt">吸入式读卡器+密码键盘</div>
        <div class="txt">均通过中国银联认证</div>
      </div>
      <div class="text">
        <div class="name">身份证阅读器</div>
        <div class="txt">符合ISO/IEC14443-2规定</div>
        <div class="txt">公安部标准通讯接口</div>
      </div>
    </div>
    <div class="paramDe">
      <div class="txt">自助入住机提高酒店运营效率、降低酒店经营成本，缩短入住时间、提升酒店形象。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {}
};
</script>
<style scoped lang="less">
.MobileCheckin {
  background-color: #fff;
  .imgBox {
    width: 100%;
    padding-top: 0.36rem;
    padding-bottom: 0.31rem;
    display: flex;
    justify-content: center;
    .img {
      width: 1.22rem;
      height: 3.74rem;
    }
  }
  .param {
    text-align: left;
    margin: 0 0.3rem;
    padding-bottom: 0.29rem;
    border-bottom: 1px solid #333;
    .title {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #333;
    }
    .tit {
      margin-top: 0.17rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.55rem;
    }
    .text {
      display: flex;
      flex-direction: column;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      margin-bottom: 0.48rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      .name {
        font-weight: bold;
      }
      .txt {
        margin-top: 0.24rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;

      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>